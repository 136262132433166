import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Customer} from '../response'
import {SchemaIDs} from '../schemas'
import {StringParams} from '../types'

const {CustomerSchemaID, CustomersSchemaID} = SchemaIDs

// API defined endpoints
export const getCustomers =
  (api: AxiosInstance) =>
  async (params: StringParams = {}, axiosConfig?: AxiosRequestConfig): Promise<Customer[]> => {
    const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
    const response = await api.get<Customer[]>('/customers', config)
    return response.data
  }

export const getCustomerById =
  (api: AxiosInstance) =>
  async (
    customerId: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Customer> => {
    const config = {params, schemaId: CustomerSchemaID, ...axiosConfig}
    const response = await api.get<Customer>(`/customers/${customerId}`, config)
    return response.data
  }

// deprecated, use getCustomerById
export const getCustomer = getCustomerById

export const getCustomersByNumber =
  (api: AxiosInstance) =>
  async (
    customerNumber: string | undefined,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Customer[]> => {
    const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
    const response = await api.get<Customer[]>(
      `/customers?customerNumber=${customerNumber}`,
      config
    )
    return response.data
  }

export const getCustomersWithoutNumber =
  (api: AxiosInstance) =>
  async (params: StringParams = {}, axiosConfig?: AxiosRequestConfig): Promise<Customer[]> => {
    const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
    const response = await api.get<Customer[]>('/customers', config)
    return response.data
  }

export const getCustomersByPayerId =
  (api: AxiosInstance) =>
  async (
    payerNumber: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Customer[]> => {
    const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
    const response = await api.get<Customer[]>(`/customers?payerId=${payerNumber}`, config)
    return response.data
  }

export const getCustomersByName =
  (api: AxiosInstance) =>
  async (
    customerName: string | undefined,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Customer[]> => {
    const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
    const response = await api.get<Customer[]>(`/customers?customerName=${customerName}`, config)
    return response.data
  }

export const getCustomersList =
  (api: AxiosInstance) =>
  async (
    customerIds: string,
    countryId: string,
    orgUnit: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Customer[]> => {
    const config = {params, schemaId: CustomersSchemaID, ...axiosConfig}
    const response = await api.get<Customer[]>(
      `/customers/list?customerIds=${customerIds}&countryId=${countryId}&orgUnit=${orgUnit}`,
      config
    )
    return response.data
  }
